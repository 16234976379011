import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProcessorOption, prysymProcessor } from 'entities/merchantServices';
import { getProcessorByProcessorId } from 'services/prysym';
import { getBusinessesId } from 'utils/authService';
import { RootState } from 'state/store';
import {
  BusinessMerchantSchema,
  addBusinessesMerchants,
  getBusinessesMerchants,
  updateBusinessesMerchants,
  deleteBusinessesMerchants,
} from 'services/businesses';

import { isMerchantUpdated, mapMerchantFields } from './utils';
import {
  getCurrentProcessor,
  getFields,
  getMerchant,
  getFormValues,
} from './selectors';

export const fetchProcessorFields: any = createAsyncThunk(
  'merchantServices/fetchProcessorFields',
  async (_: void, thunkAPI) => {
    try {
      const processor: ProcessorOption | null = getCurrentProcessor(
        thunkAPI.getState() as RootState,
      );

      const fields: any = await getProcessorByProcessorId(
        processor?.value || '',
      );

      return fields?.data ? fields.data : fields || {};
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchBusinessMerchant: any = createAsyncThunk(
  'merchantServices/fetchBusinessMerchant',
  async () => {
    try {
      const businessId: number = getBusinessesId();

      const merchant: any = await getBusinessesMerchants(businessId);

      return merchant;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const addBusinessMerchant: any = createAsyncThunk(
  'merchantServices/addBusinessMerchant',
  async (processor: ProcessorOption) => {
    try {
      const businessesId: number = getBusinessesId();

      const payload: Partial<BusinessMerchantSchema> = {
        name: processor.name,
        processor_Name: processor.name,
        processor_MerchantsId: processor.value,
        isActive: true,
      };

      const result: any = await addBusinessesMerchants(businessesId, payload);

      if (result.id) {
        return {
          ...payload,
          merchantsId: result.id,
        };
      }

      return false;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const changeBusinessMerchant: any = createAsyncThunk(
  'merchantServices/changeBusinessMerchant',
  async (processor: ProcessorOption | null, thunkAPI) => {
    try {
      const businessId: number = getBusinessesId();

      const merchant: BusinessMerchantSchema | null = getMerchant(
        thunkAPI.getState() as RootState,
      );

      if (merchant?.merchantsId) {
        await deleteBusinessesMerchants(
          businessId,
          merchant.merchantsId,
          merchant,
        );
      }

      if (processor) {
        thunkAPI.dispatch(addBusinessMerchant(processor));

        return false;
      }

      return true;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const saveBusinessMerchant: any = createAsyncThunk(
  'merchantServices/saveBusinessMerchant',
  async (_: void, thunkAPI) => {
    try {
      const businessId: number = getBusinessesId();

      const state = thunkAPI.getState() as RootState;

      const merchant = getMerchant(state);

      if (
        merchant?.merchantsId &&
        merchant.processor_MerchantsId !== prysymProcessor.value
      ) {
        const fields: any = getFields(state);
        const formValues = getFormValues(state);

        if (isMerchantUpdated(merchant, formValues)) {
          const payload: Partial<BusinessMerchantSchema> = mapMerchantFields(
            formValues,
            fields,
          );

          await updateBusinessesMerchants(
            businessId,
            merchant.merchantsId,
            payload,
          );

          return payload;
        }
      }

      return false;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
