import api from 'api';

export interface TenantSchema {
  apikey: string | null;
  colorPrimary: string | null;
  colorSecondary: string | null;
  countryCallingCode: string | null;
  createdBy: string;
  createdOn: string;
  erptenantsId: number | null;
  faviconFileShareUri: string | null;
  isActive: boolean;
  json: string | null;
  logoFileShareUri: string | null;
  logoSecondaryFileShareUri: string | null;
  modifiedBy: string | null;
  modifiedOn: string | null;
  name: string | null;
  supportEmailAddress: string | null;
  telephoneNumber: string | null;
  tenantUri: string | null;
  tenantsId: number;
  textPrimary: string | null;
  textSecondary: string | null;
  urlsub: string;
  poweredByLogoFileShareUri: string | null;
  poweredByText: string | null;
}

interface SearchTenantsPayload {
  urlsub?: string;
  isActive?: boolean;
  tenantsId?: number;
}

export const searchTenants = async (data: SearchTenantsPayload) => {
  const result: TenantSchema[] = await api.post('Tenants/SearchTenants', data);
  return result;
};
