import api from 'api';

export interface SmartCollectCreationWrapperSchema {
  businessesId: number;
}

export const smartCollectCreationWrapper = async (businessesId: number) => {
  return api.post(`Businesses/${businessesId}/SmartCollect/Participants`);
};

export default {
  smartCollectCreationWrapper,
};
