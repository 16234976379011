import { Routes, Route, Navigate } from 'react-router-dom';

import ErpCallback from 'pages/dashboard/components/AccountingSoftware/components/ErpCallback';
import PrivacyPolicy from 'pages/privacyPolicy/PrivacyPolicy';
import Businesses from 'pages/businesses/Businesses';
import TermsOfUse from 'pages/termsOfUse/TermsOfUse';
import Invitation from 'pages/invitation/Invitation';
import PrivateRoute from 'components/PrivateRoute';
import Dashboard from 'pages/dashboard/Dashboard';
import SignConsent from 'pages/eSign/SignConsent';
import Profile from 'pages/Profile/Profile';
import NotFound from 'components/NotFound';
import { DEFAULT_LANGUAGE } from 'config';
import Enroll from 'pages/enroll/Enroll';
import Login from 'pages/login/Login';
import SSO from 'pages/sso/SSO';
import path from 'common/path';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={path.login} element={<Login />} />
      <Route path={path.enroll} element={<Enroll />} />
      <Route path={path.sso} element={<SSO />} />
      <Route path={`${path.profile}/:businessId`} element={<Profile />} />
      <Route path={path.profile} element={<Profile />} />
      <Route path={path.invitation} element={<Invitation />} />
      <Route
        path={path.businesses}
        element={<PrivateRoute component={Businesses} />}
      />

      <Route
        path={path.terms}
        element={
          <Navigate to={`${path.terms}/${DEFAULT_LANGUAGE.toUpperCase()}`} />
        }
      />
      <Route path={`${path.terms}/:lang`} element={<TermsOfUse />} />
      <Route path={`${path.terms}/:country/:lang`} element={<TermsOfUse />} />

      <Route
        path={path.privacy}
        element={
          <Navigate to={`${path.privacy}/${DEFAULT_LANGUAGE.toUpperCase()}`} />
        }
      />
      <Route path={`${path.privacy}/:lang`} element={<PrivacyPolicy />} />

      <Route
        path={path.eSign}
        element={
          <Navigate to={`${path.eSign}/${DEFAULT_LANGUAGE.toUpperCase()}`} />
        }
      />
      <Route path={`${path.eSign}/:lang`} element={<SignConsent />} />

      <Route path={path.callback} element={<ErpCallback />} />
      <Route
        path={path.dashboard}
        element={<PrivateRoute component={Dashboard} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
