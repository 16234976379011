import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector, useCurrentUser } from 'hooks';
import { USER_WITHOUT_OID, USER_WITH_OID } from 'services/persons';
import LayoutSignUp from 'components/layouts/LayoutSignUp';
import { verifyPerson } from 'pages/login/thunks';
import { getUrlParams } from 'utils/url';
import path from 'common/path';
import {
  deleteBusinessesId,
  getOID,
  getUserEmail,
  setUserEmail,
  setProfileId,
} from 'utils/authService';

import ProfileContainer from './components/ProfileContainer';
import { getOnboarding, getBusiness } from './selectors';
import { fetchOrCreatePerson } from './thunks';
import {
  resetState,
  setBusiness,
  setPerson,
  setIsLoading,
  setRedirectToDashboard,
  setFormUpdateState,
  addExistingEmail,
} from './profileSlice';

const Profile = () => {
  const dispatch = useAppDispatch();
  const location: any = useLocation();
  const { user, isAuthenticated } = useCurrentUser();

  const { businessesId } = useAppSelector(getBusiness);
  const onboarding = useAppSelector(getOnboarding);
  const params: any = getUrlParams();
  const oid = getOID();

  const { businessId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (businessId) {
      setProfileId(+businessId);

      if (params?.email) {
        setUserEmail(params.email);
      }

      navigate(path.sso);
      return;
    }

    if (!onboarding && (isAuthenticated || oid)) {
      let email = user?.username || getUserEmail();

      if (!email && oid && params?.email) {
        email = params.email;
        dispatch(setRedirectToDashboard(false));
      }

      if (email) {
        dispatch(
          fetchOrCreatePerson({
            username: email,
            name: user?.name || '',
            checkStep: location?.state?.checkStep ?? true,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onboarding, user, location, isAuthenticated]);

  useEffect(() => {
    const resetProfile = async () => {
      deleteBusinessesId();
      await dispatch(resetState(user));
    };

    if (onboarding) {
      resetProfile();
    }
  }, [dispatch, onboarding, user]);

  useEffect(() => {
    const fetchData = async () => {
      if (params?.email) {
        const result = await dispatch(verifyPerson({ email: params.email }));

        if (result.payload === USER_WITH_OID) {
          dispatch(addExistingEmail({ email: params.email, hasOID: true }));
        } else if (
          (result.payload === USER_WITHOUT_OID && !businessesId) ||
          (Array.isArray(result.payload) && result.payload.length > 0)
        ) {
          dispatch(addExistingEmail({ email: params.email, hasOID: false }));
        }

        await dispatch(
          setPerson({
            givenName1: params.firstname || '',
            surnameFirst: params.lastname || '',
            emailAddress: params.email,
          }),
        );

        if (params?.company) {
          await dispatch(
            setBusiness({
              businessLegalName: params.company,
            }),
          );
        }

        dispatch(setFormUpdateState());
      }

      dispatch(setIsLoading(false));
    };

    if (!isAuthenticated && !oid) {
      dispatch(setRedirectToDashboard(false));
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <LayoutSignUp>
      <ProfileContainer />
    </LayoutSignUp>
  );
};

export default Profile;
