import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { msalInstance } from 'components/AuthWrapper';
import Loading from 'components/Loading';
import { useCurrentUser } from 'hooks';
import { loginRequest } from 'config';
import path from 'common/path';
import {
  getProfileId,
  setBusinessesId,
  removeProfileId,
  getUserEmail,
  removeUserEmail,
} from 'utils/authService';

const SSO = () => {
  const { isAuthenticated, loading } = useCurrentUser();
  const navigate = useNavigate();

  const businessId = getProfileId();
  const email = getUserEmail();

  useEffect(() => {
    const handleAuth = async () => {
      if (isAuthenticated) {
        setBusinessesId(businessId);
        removeProfileId();
        removeUserEmail();
        navigate(path.dashboard);
      } else if (!loading) {
        try {
          await msalInstance.ssoSilent({
            ...loginRequest,
            loginHint: email || '',
          });
        } catch (err) {
          await msalInstance.loginRedirect(loginRequest);
        }
      }
    };

    handleAuth();
  }, [isAuthenticated, navigate, businessId, loading, email]);

  return (
    <div className="h-screen overflow-y-auto flex">
      <Loading loading={true} />
    </div>
  );
};

export default SSO;
