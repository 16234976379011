import { createAsyncThunk } from '@reduxjs/toolkit';

import { searchTenants, TenantSchema } from 'services/tenants';
import { getBusinessesId, getProfileId } from 'utils/authService';
import {
  getSubDomain,
  isSmartHubDomain,
  setFavicon,
} from 'utils/domainService';

import { getTenantFromStorage, setTenantToStorage } from './utils';
import { getBusinesses } from 'services/businesses';

export const fetchTenant: any = createAsyncThunk(
  'theme/fetchTenant',
  async () => {
    try {
      const subDomain = getSubDomain();

      let tenant: TenantSchema | null = null;

      if (isSmartHubDomain()) {
        const businessId = getBusinessesId();
        const profileId = getProfileId();

        if (businessId || profileId) {
          const businesses = await getBusinesses(businessId || profileId);
          const business = businesses?.[0] || null;

          if (business) {
            const tenants = await searchTenants({
              tenantsId: business.tenantId,
              isActive: true,
            });

            tenant =
              Array.isArray(tenants) && tenants.length ? tenants[0] : null;
          }
        }
      } else {
        tenant = getTenantFromStorage(subDomain);

        if (!tenant) {
          const tenants = await searchTenants({
            urlsub: subDomain,
            isActive: true,
          });

          tenant = Array.isArray(tenants) && tenants.length ? tenants[0] : null;

          if (tenant) {
            setTenantToStorage(tenant);
          }
        }
      }

      if (tenant?.faviconFileShareUri) {
        setFavicon(tenant.faviconFileShareUri);
      }

      return tenant;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
