import { get, set, rm } from 'lockr';

enum Token {
  access_token = 'access_token',
  refresh_token = 'refresh_token',
  msal_access_token = 'msal_access_token',
  businessesId = 'businessesId',
  oid = 'oid',
  email = 'email',
  profileId = 'profileId',
}

const getToken = () => get(Token.msal_access_token);
const getRefreshToken = () => get(Token.refresh_token);

const login = (tokenResponse: any) => {
  set(Token.access_token, tokenResponse?.access_token);
  set(Token.refresh_token, tokenResponse?.refresh_token);
};

const setBusinessesId = (businessesId: number) => {
  set(Token.businessesId, businessesId);
};

const getBusinessesId = () => get(Token.businessesId);

const setProfileId = (profileId: number) => {
  set(Token.profileId, profileId);
};

const getProfileId = () => get(Token.profileId);

const removeProfileId = () => rm(Token.profileId);

const msalLogin = (tokenResponse: string) => {
  rm(Token.oid);
  set(Token.msal_access_token, tokenResponse);
};

const getOID = () => get(Token.oid);

const setOID = (oid: string) => {
  rm(Token.msal_access_token);
  set(Token.oid, oid);
};

const getUserEmail = () => get(Token.email);

const setUserEmail = (email: string) => {
  set(Token.email, email);
};

const removeUserEmail = () => rm(Token.email);

const deleteBusinessesId = () => {
  rm(Token.businessesId);
};

const logOut = () => {
  rm(Token.access_token);
  rm(Token.refresh_token);
  rm(Token.msal_access_token);
  rm(Token.businessesId);
  rm(Token.oid);
  rm(Token.email);
};

export {
  getRefreshToken,
  login,
  logOut,
  getToken,
  msalLogin,
  setOID,
  getOID,
  getUserEmail,
  setUserEmail,
  removeUserEmail,
  setBusinessesId,
  getBusinessesId,
  deleteBusinessesId,
  setProfileId,
  getProfileId,
  removeProfileId,
};
