import { BriefcaseIcon, SelectorIcon, CheckIcon } from 'components/icons';
import CoreCardSelect from 'components/CoreCardSelect';
import { getBusiness } from 'pages/Profile/selectors';
import ProductSelect from 'components/ProductSelect';
import BusinessList from 'components/BusinessList';
import { getPersonLetters } from 'utils/helpers';
import Popover from 'components/Popover';
import Button from 'components/Button';
import Person from 'components/Person';
import { useAppSelector } from 'hooks';

const BusinessSelector = () => {
  const { businessesId, businessLegalName, emailAddress } =
    useAppSelector(getBusiness);

  return (
    <div className="ml-15">
      <Popover
        withModal
        button={
          <Button
            variant="secondary-outline"
            size="medium"
            paddingClass="px-[9px]"
            className="focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <BriefcaseIcon className="mx-2 text-blue-600 w-[18px] h-[18px]" />
            <span className="block truncate max-w-[320px]">
              {businessLegalName}
            </span>
            <SelectorIcon className="ml-2 text-gray-500" />
          </Button>
        }
        content={
          <div
            className="bg-white rounded-md shadow-lg w-screen max-w-[360px] border border-gray-100 mt-1 overflow-y-auto custom-scrollbar"
            style={{ maxHeight: 'calc(100vh - 70px)' }}
          >
            <div className="p-[11px] border-b border-gray-200">
              <div className="rounded-md bg-gray-50 p-4">
                <div className="flex items-center">
                  <div className="flex-shrink-0 mr-3">
                    <Person
                      name={getPersonLetters(businessLegalName)}
                      index={3}
                      size="huge"
                    />
                  </div>

                  <div className="ml-3 flex-1">
                    <div className="flex items-center justify-between mb-1 w-full">
                      <p className="font-semibold text-gray-900 mr-2.5 break-all">
                        {businessLegalName}
                      </p>
                      <CheckIcon className="text-green-500 flex-shrink-0" />
                    </div>

                    <div className="flex">
                      <div className="bg-gray-100 py-px px-1 rounded-sm text-xs text-gray-700">
                        {`ID: ${businessesId}`}
                      </div>
                    </div>

                    <p className="text-secondary mb-1 break-all">
                      {emailAddress}
                    </p>
                  </div>
                </div>

                <ProductSelect />
                <CoreCardSelect />
              </div>
            </div>

            <BusinessList selected={businessesId} refreshPage />
          </div>
        }
        placement="bottom-end"
      />
    </div>
  );
};

export default BusinessSelector;
