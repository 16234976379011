import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import PermissionSection from 'components/permission/PermissionSection';
import { getApplicationStatuses } from 'pages/dashboard/selectors';
import { setApplicationStatus } from 'pages/Profile/profileSlice';
import ApplicationStatus from 'components/ApplicationStatus';
import { ApplicationStatuses } from 'entities/progress';
import { isSmartHubDomain } from 'utils/domainService';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateBusiness } from 'pages/Profile/thunks';
import { getBusiness } from 'pages/Profile/selectors';
import PoweredBy from 'components/layouts/PoweredBy';
import ProgressLine from 'components/ProgressLine';
import { Permissions } from 'entities/dashboard';
import Badge from 'components/Badge';

import ProgressProducts from './components/ProgressProducts';
import HelpDropdown from './components/HelpDropdown';
import { getProcent } from './selectors';

const Progress = () => {
  const { t } = useTranslation('dashboard');

  const dispatch = useAppDispatch();
  const business = useAppSelector(getBusiness);
  const statuses = useAppSelector(getApplicationStatuses);
  const appPercent: null | number = useAppSelector(getProcent);

  useEffect(() => {
    if (
      business.businessesId &&
      appPercent !== null &&
      appPercent !== business.completionPercentage
    ) {
      dispatch(
        updateBusiness({
          completionPercentage: appPercent,
        }),
      );
    }
  }, [
    appPercent,
    business.businessesId,
    business.completionPercentage,
    dispatch,
  ]);

  useEffect(() => {
    const updateStatus = (status) => {
      if (status) {
        dispatch(
          updateBusiness({
            applicationStatusID: status.applicationStatusId,
            applicationStatusTypeCode: status.typeCode,
          }),
        );
      }
    };

    if (appPercent !== null && appPercent !== business.completionPercentage) {
      dispatch(
        updateBusiness({
          completionPercentage: appPercent,
        }),
      );
    }

    if (business.businessesId && !business.applicationStatusTypeCode) {
      const status = statuses.find(
        (s) => s.typeCode === ApplicationStatuses.pendingEnrollment,
      );

      if (status) {
        dispatch(
          setApplicationStatus({
            applicationStatusID: status.applicationStatusId,
            applicationStatusTypeCode: status.typeCode,
          }),
        );
      }
    }

    if (
      business.applicationStatusTypeCode ===
        ApplicationStatuses.pendingEnrollment &&
      appPercent === 100
    ) {
      const status = statuses.find(
        (s) => s.typeCode === ApplicationStatuses.pendingApproval,
      );

      updateStatus(status);
    }

    if (
      business.applicationStatusTypeCode ===
        ApplicationStatuses.pendingApproval &&
      appPercent !== null &&
      appPercent < 100
    ) {
      const status = statuses.find(
        (s) => s.typeCode === ApplicationStatuses.pendingEnrollment,
      );

      updateStatus(status);
    }
  }, [
    dispatch,
    appPercent,
    business.applicationStatusTypeCode,
    business.businessesId,
    business.completionPercentage,
    statuses,
  ]);

  return (
    <div className="sm:mx-3 max-w-full sm:max-w-[363px] flex-shrink-0">
      <div
        className="sticky top-6 flex flex-col justify-between z-10"
        style={{ minHeight: 'calc(100vh - 180px)' }}
      >
        {isSmartHubDomain() && (
          <div className="p-4 shadow rounded-10 bg-white mb-6 font-semibold">
            {t('progress.profile')}
          </div>
        )}
        <div className="p-4 shadow rounded-10 bg-white mb-10">
          <div className="w-full flex items-center justify-between mb-3">
            <span className="font-semibold">{t('progress.title')}</span>
            <Badge color={appPercent === 100 ? 'green' : 'blue'}>
              {Math.floor(appPercent || 0)}%
            </Badge>
          </div>

          <ProgressLine progress={appPercent || 0} height={12} />

          <div className="mt-6">
            <ApplicationStatus
              status={business.applicationStatusTypeCode}
              withDescription
            />
          </div>

          <PermissionSection permission={Permissions.products}>
            <div className="flex flex-wrap mt-[18px] bg-gray-50 p-3 rounded-lg">
              <p className="font-medium text-gray-900">
                {t('progress.products')}
              </p>
              <ProgressProducts />
            </div>
          </PermissionSection>

          <HelpDropdown />
        </div>
        <PoweredBy />
      </div>
    </div>
  );
};

export default Progress;
