import { getDomain, getTenant } from 'theme/selectors';
import { ComplianceStatuses } from 'common/compliance';
import { getBusiness } from 'pages/Profile/selectors';
import { AngleLeftIcon } from 'components/icons';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks';
import Link from 'components/Link';
import { config } from 'config';
import {
  DomainType,
  smartHubDomain,
  isUATDomain,
  isSmartHubDomain,
} from 'utils/domainService';

import SmartSuiteLogo from './SmartSuiteLogo';

import defaulLogo from 'assets/images/logo.png';

interface LogoWithTextProps {
  className?: string;
  checkDefaultTenant?: boolean;
}

const LogoWithText: React.FC<LogoWithTextProps> = ({
  className,
  checkDefaultTenant = false,
}) => {
  const { t } = useTranslation('common');

  const { logoSecondaryFileShareUri } = useAppSelector(getTenant);
  const domain = useAppSelector(getDomain);
  const business = useAppSelector(getBusiness);

  const logo = logoSecondaryFileShareUri || defaulLogo;

  if (checkDefaultTenant && domain === DomainType.smartHub) {
    return <SmartSuiteLogo full className={className} />;
  }

  if (!logo) {
    return null;
  }

  if (isSmartHubDomain()) {
    return (
      <a
        href={`${config.PROTOCOL}${
          isUATDomain() ? 'uat.' : ''
        }${smartHubDomain}`}
        rel="noreferrer"
        className="flex items-center font-medium text-gray-700 hover:text-gray-500"
      >
        <AngleLeftIcon className="mr-2" />
        {t('backToMainNavigation')}
      </a>
    );
  }

  const imageElement = <img src={logo} className={className} alt="Logo" />;

  return business?.complianceStatusID === ComplianceStatuses.approved ? (
    <Link
      href={`${config.PROTOCOL}${isUATDomain() ? 'uat.' : ''}${smartHubDomain}`}
    >
      {imageElement}
    </Link>
  ) : (
    imageElement
  );
};

export default LogoWithText;
