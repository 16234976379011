import api from 'api';

export interface StoreNameSchema {
  storeName: string | null;
}

export const getStoreName = async (programId: number, businessId: number) => {
  const result: StoreNameSchema | null = await api.get(
    `Cards/Program/GetStoreName/${programId}?businessesId=${businessId}`,
  );

  return result?.storeName || null;
};
