const path = {
  login: '/login',
  enroll: '/enroll',
  profile: '/profile',
  sso: '/sso-silent',
  invitation: '/invitation',
  businesses: '/business-list',
  terms: '/terms-of-use',
  privacy: '/privacy-policy',
  eSign: '/e-sign-consent',
  callback: '/callback',
  dashboard: '/',
} as const;

export type Paths = typeof path[keyof typeof path];

export default path;
